import React, { Component } from "react";
import "./operation.css";
import heart from "../images/heart.png";
import transportation from "../images/transportation.png";
import { connect } from "react-redux";
import $ from "jquery";
import Section from "../section/Section";

class Operation extends Component {
  state = {};
  componentDidMount() {
    $(".operationType:first-child").addClass("activeOperation");
    $(".operationType").click(function() {
      $(".activeOperation").removeClass("activeOperation");

      $(this).addClass("activeOperation");
    });
  }
  render() {
    const renderOperations = () => {
      return this.props.operations.map((item, index) => {
        return (
          <div
            className="operationType"
            onClick={() => this.props.operationClick(item.title)}
          >
            <img src={item.img} alt="" />
            {item.title}
          </div>
        );
      });
    };

    const renderOperationsInfo = () => {
      return this.props.operations.map((item, index) => {
        if (this.props.activeOperation === item.title) {
          return (
            <Section>
              <p>{item.para1}</p>
              <p>{item.para2}</p>
              <p>{item.para3}</p>
              {item.para4 ? (
                <p>
                  {item.para4}{" "}
                  <a href="http://www.saferoofz.com/" target="_blank">
                    www.saferoofz.com
                  </a>
                </p>
              ) : (
                <React.Fragment />
              )}
            </Section>
          );
        }
      });
    };
    return (
      <div className="operationContainer">
        <div className="renderOperation">{renderOperations()}</div>
        <div className="renderOperationInfo">{renderOperationsInfo()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    operations: state.operations,
    activeOperation: state.activeOperation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    operationClick: name => dispatch({ type: "operationClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Operation);
