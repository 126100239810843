import $ from "jquery";
import { directive } from "@babel/types";

export function submit(data) {
  const product_name = data.product_name;
  const quantity = data.quantity;
  const name = data.name;
  const number = data.number;
  const email = data.email;

  // alert(data)
  if (name.length == 0) {
    alert("Please Enter Your First Name");
    return;
  }

  if (quantity.length == 0) {
    alert("Please Enter Your Last Name");
    return;
  }

  if (email.length == 0) {
    alert("Please enter valid email address");
    return;
  }
  if (validateEmail(email)) {
  } else {
    alert("Invalid Email Address");
    return;
  }
  if (number.length == 0) {
    alert("Please Enter Your Number");
    return;
  }
  if (number.length > 10) {
    alert("Invalid Number");
  }

  $("#disable2").attr("disabled", true);

  $.ajax({
    url: "/enquire.php",
    type: "POST",
    data: {
      product_name: product_name,
      quantity: quantity,
      email: email,
      number: number,
      name: name
    },
    success: function (res) {
      $("#disable2").attr("disabled", false);
      $(".productContact")
        .find("input, textarea")
        .val("");

      alert("mail sent");
    },
    error: function (e) {
      $("#disable2").attr("disabled", false);
      alert("Error Occurred");
    }
  });
}

function validateEmail(email) {
  var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  if (filter.test(email)) {
    return true;
  } else {
    return false;
  }
}
