import $ from "jquery";

export const scrollNav = (page) => {
  console.log("page" + page)
  return $("html, body").animate(
    {
      scrollTop: $("#" + page).offset().top - 100
    },
    "slow"
  );


};

export const scrollNav2 = (page) => {
  if (page === "home") {
    // return $("html, body").animate({ scrollTop: 0 }, "slow");
    return $("html, body").animate(
      {
        scrollTop: $(".headerContainer").offset().top
      },
      "slow"
    );
  }
  else {
    return $("html, body").animate(
      {
        scrollTop: $("#" + page).offset().top - 100
      },
      "slow"
    );
  }
};
