import React, { Component } from "react";
import { BrowserRouter, Route, Switch, IndexRoute } from "react-router-dom";
import Home from "./home/Home";
import Products from "./products/Products";
import ProductDetail from "./productDetail/ProductDetail";

class Main extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} exact strict />
          <Route path="/products" component={Products} exact strict />
          {/* <Route path="/products" component={Products} exact /> */}

          <Route
            path="/products/:productName"
            render={({ match }) => {
              return (
                <ProductDetail activeProductName={match.params.productName} />
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Main;
