import React, { Component } from 'react';
import "./section.css";

class Section extends Component {
    state = {}
    render() {
        return (
            <div className={"sectionContainer "
                + this.props.className}>
                {this.props.children}
            </div >
        );
    }
}

export default Section;