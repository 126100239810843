//import next from "../jsx/Animation";
import $ from "jquery";
import heart from "../images/heart.png";
import transportation from "../images/transportation.png";
import FMCG from "../images/FMCG.png";
import furnishing from "../images/furnishing.png";
import preengg from "../images/preengg.png";
import item1 from "../images/home_bg.png";

let health1 =
  "https://res.cloudinary.com/sure-fix/image/upload/v1573628841/products/Healthcare/2530_PE_FILM_with_BLUE_TRIM_jfmsuo.png";
let health2 =
  "https://res.cloudinary.com/sure-fix/image/upload/v1575355758/products/Healthcare/BSTP_2525_e34dgl.png";
let health3 =
  "https://res.cloudinary.com/sure-fix/image/upload/v1573628842/products/Healthcare/IV_Fixator_Eye_Bandage_Nonwoevn_a1loe0.png";
let health4 =
  "https://res.cloudinary.com/sure-fix/image/upload/v1573628842/products/Healthcare/PE_Film_2530_rl45pb.png";
let health5 =
  "https://res.cloudinary.com/sure-fix/image/upload/v1573628842/products/Healthcare/PE_Sheet-Eye_Drape_gudnly.png";
let health6 =
  "https://res.cloudinary.com/sure-fix/image/upload/v1573628842/products/Healthcare/PP_3077_with_FL_z3pnlq.png";
let health7 =
  "https://res.cloudinary.com/sure-fix/image/upload/v1573628842/products/Healthcare/PP_3077clear_nx4pcs.png";
let health8 =
  "https://res.cloudinary.com/sure-fix/image/upload/v1573628843/products/Healthcare/PXN_5013_PP_3077_FL_200_einp0m.png";
let health9 =
  "https://res.cloudinary.com/sure-fix/image/upload/v1573628842/products/Healthcare/SELF-_ADHESIVE_NONWOVENS_qee55f.png";

const operations = [
  {
    title: "Healthcare",
    img: heart,
    para1:
      "Surgical disposable drape manufacturing -we supply the double side drape construction tape globally, with a large market share in India.",
    para2: "",
    para3: "",
  },
  {
    title: "Mass Transportation",
    img: transportation,
    para1:
      "We manufacture self-adhesive products for the automotive sector. Specialized gasket for speedometers.",
    para2:
      "Double side tapes for behind the dash board, behind the door trim for sound dampening.",
    para3:
      "PU foam tapes for the interchangeable HVAC air ducts. Double side tapes for panel fixing.",
  },
  {
    title: "FMCG",
    img: FMCG,
    para1:
      "We provide high bond double side tapes for various packaging of FMCG products and for their printing applications.",
    para2: "",
    para3: "",
  },
  {
    title: "Furnishing fabrics",
    img: furnishing,
    para1:
      "We have developed various types of anti-fray tapes for sample swatch folders of furnishing fabrics. This includes  fabrics used for shear curtains. These are available in clear plastic to special coated papers for better printing.",
    para2: "",
    para3: "",
  },
  {
    title: "Pre-engineered building",
    img: preengg,
    para1: "",
    para2: "",
    para3: "",
    para4: "Check out our dedicated website",
  },
];

const categories = [
  {
    title: "All Products",
  },
  {
    title: "Healthcare",
  },
  {
    title: "Export oriented packaging",
  },
  {
    title: "Automotive",
  },
  {
    title: "Packaging",
  },
  {
    title: "Furnishing fabrics",
  },
  {
    title: "Roof leakage",
  },
];

const products = [
  {
    title: "21 SELF-ADHESIVE GOWN CLOSURES",
    category: "Healthcare",
    product_img: "https://res.cloudinary.com/sure-fix/image/upload/v1614167239/products/Healthcare/21_bdiogf.jpg",
    desc: "Surefix presents another gem, gown closure system which replaces the loop and hook / strings. These are multi layered self-adhesive pieces as seen in the video. Very simple to use, easy to apply and quick turn around time as compared to the string (stitching) & loop & hook cutting, alignment and stitching."
  },
  {
    title: "BVBT 1950 B",
    category: "Healthcare",
    product_img:
      "https://res.cloudinary.com/surefix/image/upload/v1586717044/products/BVBT_1950_B_nubwev.png",
    desc: "Seam Sealing Tape for Coveralls for Health Care Workers",
  },
  {
    title: "2530 PE FILM WITH BLUE TRIM",
    category: "Healthcare",
    product_img: health1,
    desc:
      "Hygiene film for incision application with blue finger lift fim, available in various sizes and can be  designed to meet your needs.",
  },
  {
    title: "BSTP 2525",
    category: "Healthcare",
    product_img: health2,
    desc: "Paper based double sided drape construction tape.",
  },
  {
    title: "IV FIXATOR & EYE BANDAGE NONWOVENS",
    category: "Healthcare",
    product_img: health3,
    desc:
      "Various products made out of spunlace non-woven (60 gsm), lint free. Available in various shapes and sizes for different application.",
  },
  {
    title: "PE Film 2530",
    category: "Healthcare",
    product_img: health4,
    desc:
      "Hygiene film for incision application - available in various sizes with (for ease of removal) or without scoring.",
  },
  {
    title: "PE SHEET-EYE DRAPE",
    category: "Healthcare",
    product_img: health5,
    desc:
      "Die cut to specific size with scoring for ease of removal and reduced wastage and saving labour time on cutting and application.",
  },
  {
    title: "PP 3077 with FL",
    category: "Healthcare",
    product_img: health6,
    desc:
      "Double side drape construction tape with finger liftfor easy removal of the release paper.",
  },
  {
    title: "PP 3077 CLEAR",
    category: "Healthcare",
    product_img: health7,
    desc:
      "Double side drape construction with tranparent look and skin friendly adhesive.",
  },
  {
    title: "PP 3077 FL 200",
    category: "Healthcare",
    product_img: health8,
    desc:
      "Double side drape construction tape with finger liftfor easy removal of the release paper, 200 meters length for convenient machine application.",
  },
  {
    title: "SELF-ADHESIVE DOUBLE SIDE FOAM TAPE",
    category: "Healthcare",
    product_img: item1,
    desc:
      "Various non-woven coated with skin friendly adhesive for dressings, taping and many such applications.",
  },
  {
    title: "HDPE ",
    category: "Export oriented packaging",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573625759/products/Export/HDPE_jlffin.png",
    desc:
      "Woven fabric tape having great tearing strength with all weather resistant adhesive.",
  },
  {
    title: "VALVE CLOSURE LABEL",
    category: "Export oriented packaging",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573625759/products/Export/Nocil_Label_hhbdyg.png",
    desc:
      "Valve closure labels available in different sizes for sealing the mouth of the multi layer wall bags. Great use for hygroscopic material.",
  },
  {
    title: "PP 4077 BROWN",
    category: "Export oriented packaging",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573625759/products/Export/PP_4077_Brown_mu8ptw.png",
    desc: "Double side filmic tape having multiple use in FIBC industry.",
  },
  {
    title: "UTILITY 1000",
    category: "Export oriented packaging",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573625760/products/Export/Utility_1000_dp12rb.png",
    desc:
      "Heavy adhesive coated with reinforcement to bond the LDPE loner to the FIBC.",
  },
  {
    title: "1mm FOAM TAPE 6060 WHITE",
    category: "Automotive",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573625718/products/Automotive/1_mm_foam_tape_6060_white_iml4ej.png",
    desc:
      "Double side foam tape for removal of NVH & superior bonidng for long period of time.",
  },
  {
    title: "1mm FOAM TAPE 8080 WHITE",
    category: "Automotive",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573625718/products/Automotive/1mm_foam_8080_white_wnnb7a.png",
    desc:
      "Double side foam tape for removal of NVH & superior bonidng for long period of time - super strong bonding.",
  },
  {
    title: "BSTP 4044",
    category: "Packaging",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1575355910/products/Packaging/BSTP_4044_sd1evd.png",
    desc: "Double side tape for water fall application.",
  },
  {
    title: "HDPE",
    category: "Packaging",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573629724/products/Packaging/HDPE_d2vuy7.png",
    desc:
      "Woven fabric tape having great tearing strength with all weather resistant adhesive.",
  },
  {
    title: "PP 4077 BROWN ",
    category: "Packaging",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573629814/products/Packaging/PP_4077_Brown_twqbiz.png",
    desc:
      "Double side filmic tape having multiple use in in the FMCG packaging industry.",
  },
  {
    title: "PP 4077 WHITE",
    category: "Packaging",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573629926/products/Packaging/PP_4077_WHITE_wsrbgp.png",
    desc:
      "Double side filmic tape having multiple use in in the FMCG packaging industry  with brown release paper for identification.",
  },
  {
    title: "CH 8040 1.0",
    category: "Furnishing fabrics",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573626088/products/Furnishing/CH_8040_1.0_kmre6h.png",
    desc:
      "Anti-fray tape with excellent adhesive strength and good printing ability.",
  },
  {
    title: "CH 8040 1.1",
    category: "Furnishing fabrics",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573626089/products/Furnishing/CH_8040_1.1_hmhn91.png",
    desc:
      "Anti-fray tape with excellent adhesive strength and good printing ability.",
  },
  {
    title: "HMA 9060",
    category: "Furnishing fabrics",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573626089/products/Furnishing/HMA_9060_ty6w3u.png",
    desc:
      "Thermal sealing tape for shear curtain material and ither difficult to seal fabrics.",
  },
  {
    title: "TAMP 8040",
    category: "Furnishing fabrics",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573626088/products/Furnishing/Tamp_8040_awotol.png",
    desc:
      "Anti-fray tape with excellent adhesive strength and excellent printing ability.",
  },
  {
    title: "C 102",
    category: "Roof leakage",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573630792/products/Roof/C_102_d3wlnx.png",
    desc: "Leakage arrestor for rusted screw head area.",
  },
  {
    title: "SILVER HDPE 1.1",
    category: "Roof leakage",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573630792/products/Roof/silver_HDPE_1_dlhppr.png",
    desc:
      "Woven fabric tape for joining / seaming the bubble insulation sheets / rolls.",
  },
  {
    title: "SP 66 1.0",
    category: "Roof leakage",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573630792/products/Roof/SP_66_1.0_uomn6o.png",
    desc:
      "Reinforced patch for sealing smaller holes created during soalr paneling work.",
  },
  {
    title: "SP 66 1.1",
    category: "Roof leakage",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573630792/products/Roof/SP_66_1.1_iphec1.png",
    desc:
      "Reinforced patch for sealing smaller holes created during soalr paneling work.",
  },
  {
    title: "SP 75 1.0 ",
    category: "Roof leakage",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573630792/products/Roof/SP_75_1.0_fhbqim.png",
    desc: "Screw head cover for preventing leakage from a screw head.",
  },
  {
    title: "SP 75 1.1",
    category: "Roof leakage",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573630792/products/Roof/SP_75_1.1_bikmt1.png",
    desc: "Screw head cover for preventing leakage from a screw head.",
  },
  {
    title: "ST 93 1.1",
    category: "Roof leakage",
    product_img:
      "https://res.cloudinary.com/sure-fix/image/upload/v1573630791/products/Roof/ST_93_1_phrxce.png",
    desc:
      "Polymeric membrane reinforced for heavy duty protection form lekages over joints of metal, FRP & polycarbonate sheets.",
  },
];

const gallery = [
  {
    title: "Industrial Video",
    link: "https://www.youtube.com/embed/V5U2r4aWecI",
    desc1:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    desc2:
      "Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    desc3:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ",
    desc4: "sometimes on purpose (injected humour and the like)",
  },
  {
    title: "Housing Video",
    link: "https://www.youtube.com/embed/QPB5HR8tqvk",
    desc1:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    desc2:
      " The first line of Lorem Ipsum, comes from a line in section 1.10.32.",
    desc3:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.",
    desc4: "",
  },
  {
    title: "Manufacturing Video",
    link: "https://www.youtube.com/embed/hd9sX7Z0GTQ",
    desc1:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab",
    desc2:
      "odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet",
    desc3: "",
    desc4: "",
  },
  {
    title: "Packaging Video",
    link: "https://www.youtube.com/embed/WfiE2luYSyY",
    desc1:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
    desc2:
      "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit ",
    desc3:
      "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment",
    desc4: "",
  },
];
const initialState = {
  activeOperation: "Healthcare",
  operations: operations,
  categories: categories,
  activeCategory: "All Products",
  products: products,
  gallery: gallery,
  activeGalleryItem: "Industrial Video",
  activeProduct: "",
  activeHead: "home",
  activePopup: false,
  isVisible: false,
  activeCatTitle: [],
  idd: 0,
  showPopup: true,
};
const reducer = (state = initialState, action) => {
  const newState = { ...state };
  // {
  //   title: "2530 PE FILM WITH BLUE TRIM",
  //   category: "Healthcare",
  //   product_img: health1,
  //   desc:"Hygiene film for incision application with blue finger lift fim, available in various sizes and can be  designed to meet your needs."
  // }
  const activeCategoryArray = () => {
    newState.activeCatTitle = [];
    products.map((item, index) => {
      if (newState.activeCategory == item.category) {
        newState.activeCatTitle.push(item.title);
      }
    });
  };
  const AllCategoryArray = () => {
    newState.activeCatTitle = [];
    products.map((item, index) => {
      newState.activeCatTitle.push(item.title);
    });
  };
  const changeActiveProject = () => {
    newState.activeCatTitle.map((item, index) => {
      if (item == newState.activeProduct) {
        newState.idd = index;
      }
    });
  };

  switch (action.type) {
    case "operationClick": {
      newState.activeOperation = action.value;
      break;
    }
    case "categoryClick": {
      newState.activeCategory = action.value;
      break;
    }
    case "galleryClick": {
      newState.activeGalleryItem = action.value;
      break;
    }
    case "itemClick": {
      newState.activeProduct = action.value;
      newState.activePopup = !newState.activePopup;
      newState.isVisible = true;
      if (newState.activeCategory === "All Products") {
        AllCategoryArray();
        changeActiveProject();
      } else {
        activeCategoryArray();
        changeActiveProject();
      }
      if (newState.activePopup === true) {
        $("html").css("overflow", "hidden");
      } else if (newState.activePopup === false) {
        $("html").css("overflow", "auto");
      }
      break;
    }
    case "headerClick": {
      newState.activeHead = action.value;
      break;
    }
    case "toggleContact": {
      newState.isVisible = false;
      break;
    }
    case "next": {
      newState.activeProduct =
        newState.activeCatTitle[
          (newState.idd + 1) % newState.activeCatTitle.length
        ];

      changeActiveProject();
      newState.isVisible = true;

      break;
    }
    case "prev": {
      newState.activeProduct =
        newState.activeCatTitle[
          (newState.activeCatTitle.length + newState.idd - 1) %
            newState.activeCatTitle.length
        ];
      changeActiveProject();
      newState.isVisible = true;

      break;
    }
    case "backClick": {
      // alert('hi')
      newState.isVisible = true;
      break;
    }
    case "togglePopup": {
      newState.showPopup = !newState.showPopup;
      if (newState.showPopup === true) {
        $("html").css("overflow", "hidden");
      } else if (newState.showPopup === false) {
        $("html").css("overflow", "auto");
      }
    }
  }
  return newState;
};

export default reducer;
