import React, { Component } from 'react';
import './gallery.css'
import Section from '../section/Section';
import { connect } from "react-redux";
import $ from "jquery";
class Gallery extends Component {
    state = {}
    componentDidMount() {
        $(".videoLink div:first-child").addClass("activeVideo");

        $(".videoLink .Link").click(function () {
            $(".activeVideo").removeClass("activeVideo");
            $(this).addClass("activeVideo");
        });
    }
    componentDidUpdate() {

    }
    render() {
        const renderVideoLink = () => {
            return this.props.gallery.map((item, index) => {
                return <div className="Link" onClick={() => this.props.galleryClick(item.title)} />

            })
        }

        const renderGallery = () => {
            return this.props.gallery.map((item, index) => {
                if (this.props.activeGalleryItem === item.title) {
                    return <React.Fragment>
                        <div className="videoContainer">
                            <iframe width="100%" height="100%" src={item.link} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                        </div>
                        <div className="descriptionContainer">
                            <Section>
                                <h1>{item.title}</h1>
                                <p>{item.desc1}</p>
                                <p>{item.desc2}</p>
                                <p>{item.desc3}</p>
                                <p>{item.desc4}</p>
                            </Section>
                        </div>
                    </React.Fragment>
                }

            })
        }
        return (<div className="galleryContainer" id="gallery">
            {renderGallery()}
            <div className="videoLink"> {renderVideoLink()}</div>
        </div>);
    }
}


const mapStateToProps = state => {
    return {
        gallery: state.gallery,
        activeGalleryItem: state.activeGalleryItem

    };
};

const mapDispatchToProps = dispatch => {
    return {
        galleryClick: name => dispatch({ type: "galleryClick", value: name })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Gallery);
