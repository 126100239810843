import React, { Component } from "react";
import "./products.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Items from "../item/Items";
import { connect } from "react-redux";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import back from "../images/back.png";
import { submit } from "../scripts/enquiremail";
let data = {};

let cat = "";
let category;
class Products extends Component {
  state = {
    quantity: "",
    name: "",
    email: "",
    contact: ""
  };
  handleChange = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChange1 = event => {
    const quantity = event.target.validity.valid
      ? event.target.value
      : this.state.quantity;

    this.setState({ quantity });
  };

  handleChange2 = event => {
    const contact = event.target.validity.valid
      ? event.target.value
      : this.state.contact;

    this.setState({ contact });
  };

  componentDidMount() {
    $(document).scrollTop(0);

    $(".filterContainer li:first-child").addClass("activecategory");

    $(".filterContainer li").click(function() {
      $(".activecategory").removeClass("activecategory");

      $(this).addClass("activecategory");
    });
    this.props.categoryClick("All Products");

    $(window).scroll(function() {
      var sticky = $(".link-container"),
        scroll = $(window).scrollTop();
      if (scroll > 50) sticky.addClass("fixed-header");
      else sticky.removeClass("fixed-header");
    });
  }
  componentDidUpdate() {
    category = document.getElementById("categoryy");
    cat = document.getElementById("cat");
    // console.log(cat=category.innerText)
    // textarea.value = hiddendiv.innerText;
  }
  render() {
    const sendDataToScript = () => {
      data.product_name = this.props.activeProduct;
      data.quantity = this.state.quantity;
      data.name = this.state.name;
      data.email = this.state.email;
      data.number = this.state.contact;
      submit(data);
    };

    const renderCategory = () => {
      return this.props.categories.map((item, index) => {
        return (
          <li onClick={() => this.props.categoryClick(item.title)}>
            {item.title}
          </li>
        );
      });
    };

    const renderItems = () => {
      return this.props.products.map((item, index) => {
        if (this.props.activeCategory === item.category) {
          return (
            <Items
              titleImg={item.product_img}
              title={item.title}
              onClick={() => this.props.itemClick(item.title)}
            />
          );
        } else if ("All Products" === this.props.activeCategory) {
          return (
            <Items
              titleImg={item.product_img}
              title={item.title}
              onClick={() => this.props.itemClick(item.title)}
            />
          );
        }
      });
    };

    const renderpopUp = () => {
      return this.props.products.map((item, index) => {
        if (this.props.activeProduct === item.title) {
          return (
            <React.Fragment>
              <div className="productImage">
                <img src={item.product_img} alt="" />
              </div>
              {this.props.isVisible ? (
                <div className="productDescription">
                  <div className="category" id="categoryy">
                    Category : {item.category}
                  </div>
                  <div className="productTitle">Product No : {item.title}</div>
                  <p>{item.desc}</p>
                  <button onClick={() => this.props.toggleContact()}>
                    Enquire
                  </button>
                </div>
              ) : (
                <div className="productContact ani-show">
                  <div
                    className="backButton"
                    onClick={() => this.props.backClick()}
                  >
                    <img src={back} alt="" />
                    <p>Back</p>
                  </div>

                  {/* <input type="textbox" placeholder="Category" readOnly value={cat} id="cat"/> */}
                  <input
                    type="textbox"
                    placeholder="Product Name"
                    value={this.props.activeProduct}
                    readOnly
                  />
                  <input
                    type="textbox"
                    placeholder="Quantity"
                    name="quantity"
                    value={this.state.quantity}
                    onInput={this.handleChange1}
                    pattern="[0-9]*"
                  />
                  <input
                    type="textbox"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  <input
                    type="textbox"
                    placeholder="Email Address"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  <input
                    type="textbox"
                    placeholder="Contact no."
                    name="contact"
                    value={this.state.contact}
                    pattern="[0-9]*"
                    onInput={this.handleChange2}
                  />
                  <button id="disable2" onClick={sendDataToScript}>
                    Send
                  </button>
                </div>
              )}
            </React.Fragment>
          );
        }
      });
    };
    return (
      <div className="productContainer" id="products">
        <div className="link-container" id="header">
          <ul>
            <NavLink to="/" onClick={() => this.props.headerClick("home")}>
              <li>Home</li>
            </NavLink>
            <NavLink to="/" onClick={() => this.props.headerClick("products")}>
              <li>Products</li>
            </NavLink>

            {/* <NavLink to="/" onClick={() => this.props.headerClick("gallery")}>
              <li>Gallery</li>
            </NavLink> */}

            <NavLink to="/" onClick={() => this.props.headerClick("contact")}>
              <li>Contact Us</li>
            </NavLink>
          </ul>
        </div>
        <Header></Header>
        <ul className="filterContainer">{renderCategory()}</ul>
        <div className="itemSection">{renderItems()}</div>
        {this.props.activePopup ? (
          <div className="overlay ani-show">
            <div className="popUp">
              <div className="close" onClick={() => this.props.itemClick()}>
                X
              </div>
              {renderpopUp()}
              <div className="changeText">
                <p onClick={() => this.props.prev()}>PREV</p>
                <p onClick={() => this.props.next()}>NEXT</p>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.categories,
    activeCategory: state.activeCategory,
    products: state.products,
    activePopup: state.activePopup,
    activeProduct: state.activeProduct,
    isVisible: state.isVisible
  };
};

const mapDispatchToProps = dispatch => {
  return {
    categoryClick: name => dispatch({ type: "categoryClick", value: name }),
    itemClick: name => dispatch({ type: "itemClick", value: name }),
    headerClick: name => dispatch({ type: "headerClick", value: name }),
    toggleContact: () => dispatch({ type: "toggleContact" }),
    next: () => dispatch({ type: "next" }),
    prev: () => dispatch({ type: "prev" }),
    backClick: () => dispatch({ type: "backClick" })

    // popUpToggle: name => dispatch({ type: "popUpToggle", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
