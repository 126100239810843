import React, { Component } from "react";
import "./footer.css";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footerContainer">
        <div className="linkContainer">
          <ul>
            <NavLink to="/" activeClassName="activeRoute">
              <li>Home</li>
            </NavLink>
            <NavLink to="/products" activeClassName="activeRoute">
              <li>Products</li>
            </NavLink>

            {/* <NavLink to='/' onClick={() => this.props.headerClick("gallery")}>

                <li>Gallery</li>
                </NavLink> */}

            {/* <NavLink to="/" onClick={() => this.props.headerClick("contact")}>
              <li>Contact Us</li>
            </NavLink> */}
          </ul>
        </div>
        <div className="copyrightContainer">
          Copyright &copy; 2019. All rights reserved. SUREFIX
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    categories: state.categories,
    activeCategory: state.activeCategory,
    products: state.products
  };
};

const mapDispatchToProps = dispatch => {
  return {
    headerClick: name => dispatch({ type: "headerClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
