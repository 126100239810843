import React, { Component } from "react";
import projectcss from "./items.css";

class Items extends Component {
    state = {};
    render() {
        return (
            <div className="ItemContainer ani-show" onClick={this.props.onClick}>
                <div className="image-container">
                    <img className="image-disp" src={this.props.titleImg} />
                    <div className="info-holder">
                        <div className="title">{this.props.title}</div>
                    </div>
                </div>
                {/* <div className="project-title">{this.props.title}</div> */}
            </div>
        );
    }
}

export default Items;
