import React, { Component } from 'react';
import "./productdetail.css";
import Header from '../header/Header'
import Footer from "../footer/Footer";
import { connect } from "react-redux";
class ProductDetail extends Component {
    state = {  }
    componentDidMount(){
    this.props.itemClick(this.props.activeProductName)
    }
    render() { 
        const renderContent=()=>{
            return this.props.products.map((item,map)=>{
               if( this.props.activeProduct===item.title)
               {
                   return <div className="productContainer">
               <div className='productImage'><img src={item.product_img} alt=""/></div>
                   
               <div className='productDescription'>
                   <div className="productTitle">
                       {item.title}
                   </div>
               <p>{item.desc}</p>
               </div>
                   </div>
               }
            })
        }
        return ( 
            <div className='productDetailContainer'>
                <Header/>
{renderContent()}
                <Footer/>
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        activeProduct: state.activeProduct,
        products:state.products
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // categoryClick: name => dispatch({ type: "categoryClick", value: name }),
        itemClick: name => dispatch({ type: "itemClick", value: name })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetail);