import React, { Component } from "react";
import "./home.css";
import Header from "../header/Header";
import Section from "../section/Section";
import Operation from "../operation/Operation";
import Footer from "../footer/Footer";
import newProduct from "../images/newProduct.jpeg";
import $ from "jquery";
import Axios from "axios";
import Products from "../products/Products";
import About from "../aboutUs/About";
import ProductHome from "../productHome/ProductHome";
import Contact from "../contact/Contact";
import Gallery from "../gallery/Gallery";
import { scrollNav, scrollNav2 } from "../common/common";
import { connect } from "react-redux";
let Video1 = "https://res.cloudinary.com/sure-fix/video/upload/v1614167889/products/Healthcare/video1_ziwco5.mp4"
class Home extends Component {
  state = {
    persons: [],
  };

  componentDidMount() {
    $(window).scroll(function () {
      var sticky = $(".link-container"),
        scroll = $(window).scrollTop();
      if (scroll > 50) sticky.addClass("fixed-header");
      else sticky.removeClass("fixed-header");
      if (scroll < 200) {
        $(".activeRoute2").removeClass("activeRoute2");
        $(".nav1").addClass("activeRoute2");
      }
      if (scroll > 640) {
        $(".activeRoute2").removeClass("activeRoute2");
        $(".nav2").addClass("activeRoute2");
      }
      if (scroll > 1200) {
        $(".activeRoute2").removeClass("activeRoute2");
        $(".nav4").addClass("activeRoute2");
      }
    });

    scrollNav2(this.props.activeHead);
    $('a[href*="#"]').on("click", function (e) {
      $("html,body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        500
      );
      e.preventDefault();
    });
    if (this.props.showPopup === true) {
      $("html").css("overflow", "hidden");
    } else if (this.props.showPopup === false) {
      $("html").css("overflow", "auto");
    }
  }

  render() {
    return (
      <div className="homeContainer">
        <div className="link-container" id="header">
          <ul>
            <a class="nav1" href="#home" id="#home">
              <li>About Us</li>
            </a>
            <a class="nav2" href="#products" id="#products">
              <li>Products</li>
            </a>
            {/* <a
              class="nav3"
              href="#gallery"
              onClick={() => this.props.headerClick("gallery")}
            >
              <li>Gallery</li>
            </a> */}
            <a class="nav4" href="#contact" id="#contact">
              <li>Contact Us</li>
            </a>
          </ul>
        </div>
        {this.props.showPopup && (
          <div className="popup ani-show">
            <div className="overlay" onClick={() => this.props.togglePopup()} />
            <video src={Video1} className="pop-section" autoPlay controls></video>
            {/* <img src={newProduct} alt="" className="pop-section" /> */}
            <p onClick={() => this.props.togglePopup()}>X</p>
          </div>
        )}
        <Header navLink={true} />
        <About />
        <ProductHome />
        {/* <Gallery /> */}
        <Contact />
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activeHead: state.activeHead,
    showPopup: state.showPopup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    headerClick: (name) => dispatch({ type: "headerClick", value: name }),
    togglePopup: () => dispatch({ type: "togglePopup" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
