import React, { Component } from "react";
import "./header.css";
import email from "../images/email.png";
import phone from "../images/phone.png";
import $ from "jquery";
import logo from "../images/logo.png";
import menu from "../images/menu.png";
import close from "../images/close.png";
import { NavLink } from "react-router-dom";
import { scrollNav } from "../common/common";
import { connect } from "react-redux";

class Header extends Component {
  state = {};
  closeToggle() {
    $(".Menu-container").removeClass("animated slideInRight animation");
    $("Menu-container").addClass(
      "animated fadeOutRight delay-1000ms animation"
    );
    $(".Menu-container").css("opacity", "0");
    setTimeout(function() {
      $(".Menu-container").css("display", "none");
    }, 500);
  }

  menuToggle() {
    $(".Menu-container").css("opacity", "1");
    $(".Menu-container").removeClass("animated fadeOutRight animation");
    $(".Menu-container").addClass("animated slideInRight animation");
    setTimeout(function() {
      $(".Menu-container").css("display", "block");
    }, 100);
  }

  componentDidMount() {}
  render() {
    return (
      <div className="headerContainer" id="home">
        <div className="firseSection">
          <div className="infoContainer">
            <div className="info">
              <img src={email} alt="" />
              <span>
                <a href="mailto:Sales@surefix.co.in">Sales@surefix.co.in</a>
              </span>
            </div>
            <div className="info">
              <img src={phone} alt="" className="phone-adjust" />
              <span>
                <a href="tel:+919820379703">+91-9820379703</a>
              </span>
            </div>
          </div>
        </div>
        <div className="secondSection">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
          {this.props.navLink ? (
            <ul>
              <a
                class="nav1 activeRoute"
                href="#home"
                onClick={() => this.props.headerClick("home")}
              >
                <li>About Us</li>
              </a>
              <a
                class="nav2"
                href="#products"
                onClick={() => this.props.headerClick("products")}
              >
                <li>Products</li>
              </a>
              {/* <a
                class="nav3"
                href="#gallery"
                onClick={() => this.props.headerClick("gallery")}
              >
                <li>Gallery</li>
              </a> */}
              <a
                class="nav4"
                href="#contact"
                onClick={() => this.props.headerClick("contact")}
              >
                <li>Contact Us</li>
              </a>
            </ul>
          ) : (
            <ul>
              <NavLink to="/" onClick={() => this.props.headerClick("home")}>
                <li>Home</li>
              </NavLink>
              <NavLink
                to="/"
                onClick={() => this.props.headerClick("products")}
              >
                <li>Products</li>
              </NavLink>

              {/* <NavLink to='/' onClick={() => this.props.headerClick("gallery")}>

                <li>Gallery</li>
                </NavLink> */}

              <NavLink to="/" onClick={() => this.props.headerClick("contact")}>
                <li>Contact Us</li>
              </NavLink>
            </ul>
          )}

          <img
            src={menu}
            alt=""
            className="menu"
            onClick={() => this.menuToggle()}
          />

          <div className="Menu-container animated slideInRight delay-1000ms animation">
            <div className="close" onClick={() => this.closeToggle()}>
              <img src={close} alt="" />
              {/* CLOSE */}
            </div>
            <ul>
              <NavLink activeClassName="activeRoute" to="/" exact>
                <li>Home</li>
              </NavLink>
              <NavLink to="/products" activeClassName="activeRoute">
                <li>Products</li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    categories: state.categories,
    activeCategory: state.activeCategory,
    products: state.products
  };
};

const mapDispatchToProps = dispatch => {
  return {
    headerClick: name => dispatch({ type: "headerClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
