import React, { Component } from 'react';
import './contact.css'
import map from '../images/map.png'
import phone from '../images/phone.png'
import email from '../images/email.png'
import { submit } from "../scripts/mail";
import placeholder from '../images/placeholder.png'
let data = {};
class Contact extends Component {
    state = {
        value1:"",
        value2:"",
        value3:"",
        value4:"",
        value5:"",
    }
    handleChange1 = event => {
        const value4 = event.target.validity.valid
          ? event.target.value
          : this.state.value4;
    
        this.setState({ value4 });
      };
    handleChange2 = event => {
        this.setState({
            value1: event.target.value
          });
      };
      handleChange3 = event => {
        this.setState({
            value2: event.target.value
          });
      };
      handleChange4 = event => {
        this.setState({
            value3: event.target.value
          });
      };
      handleChange5 = event => {
        this.setState({
            value5: event.target.value
          });
      };
    
    render() {
        const sendDataToScript =() => {
            data.Fname = this.state.value1;
            data.Lname = this.state.value2;
            data.EmailID = this.state.value3;
            data.MobNumber = this.state.value4;
            data.Message = this.state.value5;
            submit(data);
          };
        return (
            <div className="contactContainer" id="contact">
                <p>Leave Us A Message</p>
                {/* place-card */}
                <div className="inputContainer">

                    <div className="leftInput" id="leftInput">
                        <input type='textbox' placeholder='First Name' value={this.state.value1} onChange={this.handleChange2} name="value1"/>
                        <input type='textbox' placeholder='Last Name' value={this.state.value2} onChange={this.handleChange3} name="value2"/>
                        <input type='textbox' placeholder='Email Address' value={this.state.value3} onChange={this.handleChange4} name="value3" />
                   
                        <input type='textbox' placeholder='Contact no.' value={this.state.value4} onInput={this.handleChange1} pattern="[0-9]*" name="value4"/>

                    </div>
                    <div className="rightInput">
                        <textarea placeholder="Message..." value={this.state.value5} onChange={this.handleChange5} name="value5" />
                    </div>
                    <button id="disable" onClick={sendDataToScript}> Send </button>
                </div>
                <div className="addressContainer">
                    <a href="https://www.google.com/maps/place/S+Doshi+Papers+Industries+Pvt.+Ltd./@19.1103832,72.8860795,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7c80d37b4c411:0x38855d8c25d4e091!8m2!3d19.1103781!4d72.8882682?hl=en" target='_blank'><img src={map} alt="" /></a>

                    <div className="address">
                        <div className="details">
                            <div className="info">
                                <div className="icon">
                                    <img src={email} alt="" />
                                </div>
                                <div className="text"><a href="mailto:vilas@surefix.co.in">vilas@surefix.co.in</a></div>
                            </div>
                            <div className="info">
                                <div className="icon">
                                    <img src={phone} className='adjustImg' alt="" />
                                </div>
                                <div className="text"><a href="tel:+919820379703">+91 98203 79703</a> / <a href="tel:+912228472588">+91 22 2847 2588</a></div>
                            </div>
                            <div className="info">
                                <div className="icon">
                                    <img src={placeholder} alt="" />
                                </div>
                                <div className="text">
                                <a href="https://www.google.com/maps/place/S+Doshi+Papers+Industries+Pvt.+Ltd./@19.1103832,72.8860795,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7c80d37b4c411:0x38855d8c25d4e091!8m2!3d19.1103781!4d72.8882682?hl=en" target='_blank'>C-15, Ansa Industrial Estate,<br />
                                    Saki Vihar Road,<br />
                                    Andheri-East, Mumbai 400 072</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;