import React, { Component } from "react";
import "./about.css";
import Header from "../header/Header";
import Section from "../section/Section";
import Operation from "../operation/Operation";
import Footer from "../footer/Footer";
import home_bg from "../images/home_bg.png";
import home_bg2 from "../images/home_bg2.png";
import $ from "jquery";
import Axios from "axios";
import Products from "../products/Products";

class Home extends Component {
  state = {
    persons: [],
  };

  render() {
    return (
      <div className="aboutMainContainer">
        <img src={home_bg} alt="" className="homeBG" />
        <img src={home_bg2} alt="" className="homeBG2" />
        <div className="aboutContainer">
          <Section className="home-tab-adjust">
            <h1>About us</h1>
            <p>
              S Doshi Papers Industries pvt was established in <span>1984</span>
              . We started our journey as a label stock (sticker paper)
              manufacturer and have grown since then into a solutions-driven
              company run by a rather dedicated team.
            </p>
            <p>
              We perform consistently through a stringent system of{" "}
              <span>ISO 9001:2015</span> certified by <span>SGS-Ukas</span>.
            </p>
            <p>
              Our endeavor has been to provide unparalleled product qualityand
              service across the verticals in which we operate, around the
              globe.
            </p>
          </Section>

          <Section className="home-margin-adjust">
            <h1>Our Philosophy</h1>
            <p>
              To address industry problems through innovative applications of
              self -adhesive products
            </p>
          </Section>

          <Section>
            <h1>The verticals in which we operate</h1>
          </Section>
          <Operation />
        </div>
      </div>
    );
  }
}

export default Home;
