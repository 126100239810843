import React, { Component } from 'react';
import "./productHome.css";
import { connect } from "react-redux";
import Items from "../item/Items";
import { NavLink } from "react-router-dom";

// import $ from "jquery";
class ProductHome extends Component {
    state = {}
    render() {
        const renderProducts = () => {
            return this.props.products.map((item, index) => {
                if (Number(index + 1) <= 7) {
                    return <Items
                        titleImg={item.product_img}
                        title={item.title}
                    />
                }

            })
        }
        return (
            <div className='productHomeContainer' id='products'>
                <div className="productHeader">
                    Products
                </div>
                <div className="renderProducts">
                    {renderProducts()}
                    <div className="viewProducts">
                        <NavLink to='/products'>
                            <button>
                                View All Products
                            </button>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        categories: state.categories,
        activeCategory: state.activeCategory,
        products: state.products
    };
};

const mapDispatchToProps = dispatch => {
    return {
        categoryClick: name => dispatch({ type: "categoryClick", value: name })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductHome);